<template>
  <DeveloperSettings
    :router="$router"
    :store="$store"
    :origin="'usr'"
    @back="$router.replace({ name: 'Home' })"
  />
</template>

<script>
import { DeveloperSettings } from '@seliaco/red-panda'

export default {
  name: 'NotificationSettingsContainer',
  components: {
    DeveloperSettings
  }
}
</script>
